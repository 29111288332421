.w-100 {
width:100% !important;
}

.pt-20px {
padding-top:20px !important;
}

@media screen and (min-width: 1200px) {.py-xl-20px {
padding-top:20px !important;padding-bottom:20px !important;
}

}
 @media screen and (min-width: 1200px) {.px-xl-25px {
padding-left:25px !important;padding-right:25px !important;
}

}
 @media screen and (min-width: 1200px) {.mb-xl-0 {
margin-bottom:0px !important;
}

}
 .mb-20px {
margin-bottom:20px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.letter-spacing-0 {
letter-spacing:0px !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.m-0 {
margin:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.pl-0 {
padding-left:0px !important;
}

@media screen and (min-width: 992px) {.mt-lg-5px {
margin-top:5px !important;
}

}
 .mt-10px {
margin-top:10px !important;
}

.mr-5px {
margin-right:5px !important;
}

.h-100 {
height:100% !important;
}

.pt-100px {
padding-top:100px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mx-15px {
margin-left:15px !important;margin-right:15px !important;
}

@media screen and (min-width: 768px) {.mb-md-0 {
margin-bottom:0px !important;
}

}
 .mb-40px {
margin-bottom:40px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mx-15px {
margin-left:15px !important;margin-right:15px !important;
}

.py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 1200px) {.py-xl-125px {
padding-top:125px !important;padding-bottom:125px !important;
}

}
 .mb-15px {
margin-bottom:15px !important;
}

.h-100 {
height:100% !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-110px {
padding-top:110px !important;padding-bottom:110px !important;
}

}
 .px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.mb-55px {
margin-bottom:55px !important;
}

@media screen and (min-width: 992px) {.px-lg-10px {
padding-left:10px !important;padding-right:10px !important;
}

}
 @media screen and (min-width: 992px) {.mb-lg-20px {
margin-bottom:20px !important;
}

}
 .mb-30px {
margin-bottom:30px !important;
}

.w-100 {
width:100% !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mt-5px {
margin-top:5px !important;
}

.pt-115px {
padding-top:115px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

.mb-60px {
margin-bottom:60px !important;
}

@media screen and (min-width: 992px) {.pr-lg-35px {
padding-right:35px !important;
}

}
 .mb-35px {
margin-bottom:35px !important;
}

.mb-5px {
margin-bottom:5px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mb-40px {
margin-bottom:40px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .mb-50px {
margin-bottom:50px !important;
}

.mr-5px {
margin-right:5px !important;
}

.mt-5px {
margin-top:5px !important;
}

.w-100 {
width:100% !important;
}

.pb-55px {
padding-bottom:55px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-15px {
margin-left:15px !important;margin-right:15px !important;
}

.py-60px {
padding-top:60px !important;padding-bottom:60px !important;
}

.pb-30px {
padding-bottom:30px !important;
}

.m-0 {
margin:0px !important;
}

.mb-40px {
margin-bottom:40px !important;
}

.mx-20px {
margin-left:20px !important;margin-right:20px !important;
}

.mr-5px {
margin-right:5px !important;
}

.mt-20px {
margin-top:20px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.w-100 {
width:100% !important;
}

.pt-15px {
padding-top:15px !important;
}

.mr-5px {
margin-right:5px !important;
}

